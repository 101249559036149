import * as React from "react"
import { Col, Container, Row } from "react-bootstrap"
import Layout from "../../../components/Layout"
import Seo from "../../../components/Seo"
import * as styles from "./PrivacyPage.module.scss"

const PrivacyPage = () => {
  return (
    <Layout pushTop>
      <Seo title="Privacy policy" />

      <div className={styles.pageContent}>
        <Container fluid="xxl">
          <Row>
            <Col md="6">
              <h1 className="text-uppercase mb-5">Privacy policy</h1>
            </Col>
          </Row>

          <h2 className="text-uppercase my-5">Our Privacy Principle</h2>

          <Row>
            <Col md={{ span: 4, offset: 6 }}>
              <p>
                When it comes to protecting your data, at GritLife we make no
                exceptions.
              </p>
              <p>
                This is why unless you specifically authorise to do so, we will
                not:
              </p>
              <ul>
                <li>Sell</li>
                <li>Trade</li>
                <li>Rent</li>
                <li>Share</li>
              </ul>
              <p>
                Your data with any third parties for advertising or marketing
                purposes. We believe you and your family’s data should always
                stay private, safe and secure. Any information we collect about
                you is only used by GritLife to provide you with the best
                possible experience.
              </p>
            </Col>
          </Row>

          <h2 className="text-uppercase my-5">Overview</h2>

          <Row>
            <Col md={{ span: 4, offset: 6 }}>
              <p>
                This Privacy Policy describes Our policies and procedures on the
                collection, use and disclosure of Your information when You use
                the Service and tells You about Your privacy rights and how the
                law protects You.
              </p>
              <p>
                We use Your Personal data to provide and improve the Service. By
                using the Service, You agree to the collection and use of
                information in accordance with this Privacy Policy.
              </p>
            </Col>
          </Row>

          <h2 className="text-uppercase my-5">
            Interpretation and Definitions
          </h2>

          <Row>
            <Col md={{ span: 4, offset: 6 }}>
              <h4>Interpretation</h4>
              <p>
                The words in which the initial letter is capitalized have
                meanings defined under the following conditions. The following
                definitions shall have the same meaning regardless of whether
                they appear in singular or in the plural.
              </p>
              <h4>Definitions</h4>
              <p>For the purposes of this Privacy Policy:</p>
              <p>
                <strong>Account</strong> means a unique account created for You
                to access the Service or parts of the Service.
              </p>
              <p>
                <strong>Affiliate</strong> means an entity that controls, is
                controlled by or is under common control with a party, where
                "control" means ownership of 50% or more of the shares, equity
                interest or other securities entitled to vote for the election
                of directors or other managing authority.
              </p>
              <p>
                <strong>Application</strong> means the software program provided
                by the Company downloaded by You on any electronic device, named
                GritLife
              </p>
              <p>
                <strong>Company</strong> (referred to as either "the Company",
                "We", "Us" or "Our" in this Agreement) refers to GritLife Pty
                Ltd., Shop H25 7 High Street Victoria Point 4165.
              </p>
              <p>
                <strong>Country</strong> refers to Queensland, Australia
              </p>
              <p>
                <strong>Device</strong> means any device that can access the
                Service such as a computer, a cellphone or a digital tablet.
              </p>
              <p>
                <strong>Personal Data</strong> is any information that relates
                to an identified or identifiable individual.
              </p>
              <p>
                <strong>Service</strong> refers to the Website and Mobile
                Application.
              </p>
              <p>
                <strong>Service Provider</strong> means any natural or legal
                person who processes the data on behalf of the Company. It
                refers to third-party companies or individuals employed by the
                Company to facilitate the Service, to provide the Service on
                behalf of the Company, to perform services related to the
                Service or to assist the Company in analyzing how the Service is
                used.
              </p>
              <p>
                <strong>Third-Parties / Third-Party</strong> refers to any
                website, partner or Service Provider the Company provides user
                data to in provisioning of the Service.
              </p>
              <p>
                <strong>Usage Data</strong> refers to data collected
                automatically, either generated by the use of the Service or
                from the Service infrastructure itself (for example, the
                duration of a page visit).
              </p>
              <p>
                <strong>You</strong> means the individual accessing or using the
                Service, or the company, or other legal entity on behalf of
                which such individual is accessing or using the Service, as
                applicable.
              </p>
            </Col>
          </Row>

          <h2 className="text-uppercase my-5">
            Collecting and Using Your Personal Data
          </h2>

          <Row>
            <Col md={{ span: 4, offset: 6 }}>
              <h4>Regulations</h4>
              <p>
                Your information is protected under the Australian Privacy Act
                1988.
              </p>
              <p>Types of Data Collected</p>
              <h5>Personal Data</h5>
              <p>
                While using the Service, We may ask You to provide Us with
                certain personally identifiable information that can be used to
                contact or identify You. Personally, identifiable information
                may include, but is not limited to:
              </p>
              <ul>
                <li>Email address</li>
                <li>Your first name and last name</li>
                <li>Your date of birth</li>
                <li>
                  First name and last name of any family member you add to your
                  profile
                </li>
                <li>
                  Date of birth of any family member you add to your profile
                </li>
                <li>Phone number</li>
                <li>Address, State, Province, ZIP/Postal code, City</li>
                <li>Usage Data</li>
              </ul>
              <h5>Usage Data</h5>
              <p>
                Usage Data will be collected automatically through the use of
                the Service. Usage Data may include information such as Your
                Device's Internet Protocol address (e.g. IP address), browser
                type, browser version, the pages of the Service that You visit,
                the time and date of Your visit, the time spent on those pages,
                unique device identifiers and other diagnostic data.
              </p>
              <p>
                When You access the Service by or through a mobile device, We
                may collect certain information automatically, including, but
                not limited to:
              </p>
              <ul>
                <li>the type of mobile device You use</li>
                <li>Your mobile device unique ID</li>
                <li>the IP address of Your mobile device</li>
                <li>Your mobile operating system</li>
                <li>the type of mobile Internet browser You use</li>
                <li>unique device identifiers and other diagnostic data.</li>
              </ul>
              <p>
                We may also collect information that Your browser sends whenever
                You visit the Service or when You access the Service by or
                through a mobile device.
              </p>
              <h5>Information from Third-Party Services</h5>
              <p>
                The Company allows You to create an account and log in to use
                the Service through the following Third-party Services:
              </p>
              <ul>
                <li>Apple</li>
                <li>Google</li>
                <li>Facebook</li>
              </ul>
              <p>
                If You decide to register through or otherwise grant us access
                to a Third-Party Social Media Service, We may collect Personal
                data that is already associated with Your Third-Party Social
                Media Service's account, such as Your name, Your email address,
                Your activities or Your contact list associated with that
                account.
              </p>
              <p>
                You may also have the option of sharing additional information
                with the Company through Your Third-Party Social Media Service's
                account. If You choose to provide such information and Personal
                Data, during registration or otherwise, You are giving the
                Company permission to use, share, and store it in a manner
                consistent with this Privacy Policy.
              </p>
              <h5>Information Collected while Using the Application</h5>
              <p>
                While using Our Application, in order to provide features of Our
                Application, We may collect, with Your prior permission:
              </p>
              <ul>
                <li>Information regarding your location</li>
                <li>
                  Information from your Device's phone book (contacts list)
                </li>
                <li>
                  Pictures and other information from your Device's camera and
                  photo library
                </li>
              </ul>
              <p>
                We use this information to provide features of the Service, to
                improve and customize the Service. The information may be
                uploaded to the Company's servers and/or a Service Provider's
                server or it may be simply stored on Your device.
              </p>
              <p>
                You can enable or disable access to this information at any
                time, through Your Device settings.
              </p>
            </Col>
          </Row>

          <h2 className="text-uppercase my-5">Use of Your Personal Data</h2>

          <Row>
            <Col md={{ span: 4, offset: 6 }}>
              <p>
                The Company may use Personal Data for the following purposes:
              </p>
              <p>
                <strong>To provide and maintain the Service:</strong> including
                monitoring the usage of the Service.
              </p>
              <p>
                <strong>To manage Your Account:</strong> to manage Your
                registration as a user of the Service. The Personal Data You
                provide can give You access to different functionalities of the
                Service that are available to You as a registered user.
              </p>
              <p>
                <strong>For the performance of a contract:</strong> the
                development, compliance and undertaking of the purchase contract
                for the products, items or services You have purchased or of any
                other contract with Us through the Service.
              </p>
              <p>
                <strong>To contact You:</strong> To contact You by email,
                telephone calls, SMS, or other equivalent forms of electronic
                communication, such as a mobile application's push notifications
                regarding updates or informative communications related to the
                functionalities, products or contracted services, including the
                security updates, when necessary or reasonable for their
                implementation.
              </p>
              <p>
                <strong>To provide You:</strong> with news, special offers and
                general information about other goods, services and events which
                we offer that are similar to those that you have already
                purchased or enquired about unless You have opted out of
                receiving such information.
              </p>
              <p>
                <strong>To manage Your requests:</strong> To attend and manage
                Your requests to Us.
              </p>
              <p>
                <strong>For business transfers:</strong> We may use Your
                information to evaluate or conduct a merger, divestiture,
                restructuring, reorganization, dissolution, or another sale or
                transfer of some or all of Our assets, whether as a going
                concern or as part of bankruptcy, liquidation, or similar
                proceeding, in which Personal Data held by Us about the Service
                users is among the assets transferred.
              </p>
              <p>
                <strong>For other purposes:</strong> We may use Your information
                for other purposes, such as data analysis, identifying usage
                trends, determining the effectiveness of our promotional
                campaigns and evaluating and improving the Service, products,
                services, marketing and Your experience.
              </p>
              <p>
                We may share Your personal information in the following
                situations:
              </p>
              <ul>
                <li>
                  <strong>With Third-Parties:</strong> We may share Your
                  personal information with Service Providers to monitor and
                  analyze the use of the Service, to contact You.
                </li>
                <li>
                  <strong>For business transfers:</strong> We may share or
                  transfer Your personal information in connection with, or
                  during negotiations of, any merger, sale of company assets,
                  financing, or acquisition of all or a portion of Our business
                  to another company.
                </li>
                <li>
                  <strong>With Affiliates:</strong> We may share Your
                  information with Our affiliates, in which case we will require
                  those affiliates to honour this Privacy Policy. Affiliates
                  include Our parent company and any other subsidiaries, joint
                  venture partners or other companies that We control or that
                  are under common control with Us.
                </li>
                <li>
                  <strong>With other users:</strong> when You share personal
                  information or otherwise interact in the public areas with
                  other users, such information may be viewed by all users and
                  may be publicly distributed outside. If You interact with
                  other users or register through a Third-Party Social Media
                  Service, Your contacts on the Third-Party Social Media Service
                  may see Your name, profile, pictures and description of Your
                  activity. Similarly, other users will be able to view
                  descriptions of Your activity, communicate with You and view
                  Your profile.
                </li>
                <li>
                  <strong>With Your Consent:</strong> We may disclose Your
                  personal information for any other purpose with Your consent.
                </li>
              </ul>
              <p>
                <strong>Law enforcement:</strong> Under certain circumstances,
                the Company may be required to disclose Your Personal Data if
                required to do so by law or in response to valid requests by
                public authorities (e.g. a court or a government agency).
              </p>
              <p>
                The Company may disclose Your Personal Data in the good faith
                belief that such action is necessary to:
              </p>
              <ul>
                <li>Comply with a legal obligation</li>
                <li>
                  Protect and defend the rights or property of the Company
                </li>
                <li>
                  Prevent or investigate possible wrongdoing in connection with
                  the Service
                </li>
                <li>
                  Protect the personal safety of users of the Service or the
                  public
                </li>
                <li>Protect against legal liability</li>
              </ul>
            </Col>
          </Row>

          <h2 className="text-uppercase my-5">Third-Party Analytics</h2>

          <Row>
            <Col md={{ span: 4, offset: 6 }}>
              <p>
                We use Third-Parties to help us understand how people use the
                Service. As part of these services only non-identifying
                statistical information is collected, such as:
              </p>

              <ul>
                <li>Device ID</li>
                <li>Device Operating System (OS)</li>
                <li>Date and Time spent on the Service</li>
                <li>Content or Pages accessed</li>
                <li>
                  How you accessed the Service (“referral website”, “Referring
                  traffic”)
                </li>
              </ul>
              <p>
                Any information collected here is subject to the Third-Parties
                terms of service, available:
              </p>
              <ul>
                <li>
                  <a href="https://marketingplatform.google.com/about/analytics/terms/gb/">
                    Google Marketing Platform
                  </a>
                </li>
                <li>
                  <a href="https://help.apple.com/app-store-connect/#/dev5340bf481">
                    Apple App Analytics Platform
                  </a>
                </li>
              </ul>

              <p>
                You can read more about how{" "}
                <a href="https://policies.google.com/privacy?hl=en">
                  Google uses your personal information
                </a>
                . You can also{" "}
                <a href="https://tools.google.com/dlpage/gaoptout">
                  opt-out of Google Analytics
                </a>{" "}
                to prevent your data from being used by Google. You can{" "}
                <a href="https://developer.apple.com/app-store/user-privacy-and-data-use/">
                  opt out of Apple Analytics by accessing your privacy and
                  security settings
                </a>{" "}
                from your Apple device.
              </p>
            </Col>
          </Row>

          <h2 id="cookies" className="text-uppercase my-5">
            Cookies
          </h2>

          <Row>
            <Col md={{ span: 4, offset: 6 }}>
              <p>
                The Company may receive information from cookies, which are
                pieces of data your browser stores and sends back to Us when
                making requests, and similar technologies. Cookies are used to
                improve Your experience, understand user activity, personalize
                content and advertisements, and improve the quality of the
                Services.
              </p>
              <p>
                For example, We store and retrieve information about Your
                preferred language and other settings.
              </p>
              <p>
                Cookies do not record any personal information or identify the
                user.
              </p>
              <p>
                Most web browsers are set to accept cookies by default. If You
                prefer, You can choose to set Your browser to remove or reject
                first- and third-party cookies.
              </p>
              <p>
                Please note that if You choose to remove or reject cookies, this
                could affect the availability and functionality of the Services.
              </p>
              <p>
                For more information, go to Your browser’s Help section or
                Settings.
              </p>
            </Col>
          </Row>

          <h2 className="text-uppercase my-5">
            Retention of Your Personal Data
          </h2>

          <Row>
            <Col md={{ span: 4, offset: 6 }}>
              <p>
                The Company will retain Your Personal Data only for as long as
                is necessary for the purposes set out in this Privacy Policy.
              </p>
              <p>
                We will retain and use Your Personal Data to the extent
                necessary to comply with our legal obligations (for example, if
                We are required to retain Your data to comply with applicable
                laws), resolve disputes, and enforce our legal agreements and
                policies.
              </p>
              <p>
                The Company will also retain Usage Data for internal analysis
                purposes. Usage Data is generally retained for a shorter period
                of time, except when this data is used to strengthen the
                security or to improve the functionality of the Service, or We
                are legally obligated to retain this data for longer time
                periods.
              </p>
            </Col>
          </Row>

          <h2 className="text-uppercase my-5">
            Transfer of Your Personal Data
          </h2>

          <Row>
            <Col md={{ span: 4, offset: 6 }}>
              <p>
                Your information, including Personal Data, is processed at the
                Company's operating offices and in any other places where the
                parties involved in the processing are located. It means that
                this information may be transferred to — and maintained on —
                computers located outside of Your state, province, country or
                other governmental jurisdiction where the data protection laws
                may differ from those from Your jurisdiction.
              </p>
              <p>
                Your consent to this Privacy Policy followed by Your submission
                of such information represents Your agreement to that transfer.
              </p>
              <p>
                The Company will take all steps reasonably necessary to ensure
                that Your data is treated securely and in accordance with this
                Privacy Policy and no transfer of Your Personal Data will take
                place to an organization or a country unless there are adequate
                controls in place including the security of Your data and other
                Personal Data.
              </p>
            </Col>
          </Row>

          <h2 className="text-uppercase my-5">
            Security of Your Personal Data
          </h2>

          <Row>
            <Col md={{ span: 4, offset: 6 }}>
              <p>
                The security of Your Personal Data is important to Us, but
                remember that no method of transmission over the Internet, or
                method of electronic storage is 100% secure. While We strive to
                use commercially acceptable means to protect Your Personal Data,
                We make no guarantee of its security.
              </p>
              <h4>Children's Privacy</h4>
              <p>
                The Service does not address anyone under the age of 18. We do
                not knowingly collect personally identifiable information from
                anyone under the age of 18 without parent or guardian consent.
              </p>
              <p>
                If You are a parent or guardian and You are aware that Your
                child has provided Us with Personal Data without your consent,
                please Contact Us.
              </p>
              <p>
                If We become aware that We have collected Personal Data from
                anyone under the age of 18 without verification of parental
                consent, We take steps to remove that information from Our
                servers.
              </p>
              <p>
                If We need to rely on consent as a legal basis for processing
                Your information and Your country requires consent from a
                parent, We may require Your parent's consent before We collect
                and use that information.
              </p>
              <h4>Links to Other Websites</h4>
              <p>
                The Service may contain links to other websites that are not
                operated by Us. If You click on a third party link, You will be
                directed to that third party's site.
              </p>
              <p>
                We strongly advise You to review the Privacy Policy of every
                site You visit.
              </p>
              <p>
                We have no control over and assume no responsibility for the
                content, privacy policies or practices of any third party sites
                or services.
              </p>
            </Col>
          </Row>

          <h2 className="text-uppercase my-5">EU and Californian Residents</h2>

          <Row>
            <Col md={{ span: 4, offset: 6 }}>
              <p>
                If You are based in the European Union or California, there are
                specific regulations that apply in relation to the collection,
                processing and storing of Your personal information by
                organisations covered by those regulations.
              </p>
              <p>
                To the extent that the Company are legally required to comply
                with those regulations in relation to any personal information
                and usage data collected, processed, disclosed or stored about
                You, the Company will comply with those regulations.
              </p>
              <p>
                Please note that the Company does not develop, target or direct
                the Service towards individuals situated outside Australia. The
                Service is targeted at individuals who are located in Australia.
                Even if You are able to subscribe to the Services from outside
                Australia.
              </p>
              <p>
                If You would like to request the Company to look into removing
                Your data from our servers, please contact our Data Security
                Officer at{" "}
                <a href="mailto:support@gritlife.app">support@gritlife.app</a>.
              </p>
            </Col>
          </Row>

          <h2 className="text-uppercase my-5">
            Changes to this Privacy Policy
          </h2>

          <Row>
            <Col md={{ span: 4, offset: 6 }}>
              <p>
                We may update Our Privacy Policy from time to time. We will
                notify You of any changes by posting the new Privacy Policy on
                this page.
              </p>
              <p>
                We will let You know via email and/or a prominent notice on the
                Service, prior to the change becoming effective and update the
                "Last Updated" date at the top of this Privacy Policy.
              </p>
              <p>
                You are advised to review this Privacy Policy periodically for
                any changes. Changes to this Privacy Policy are effective when
                they are posted on this page.
              </p>
            </Col>
          </Row>

          <h2 className="text-uppercase my-5">Contact Us</h2>

          <Row className="mb-5">
            <Col md={{ span: 4, offset: 6 }}>
              <p>
                If you have any questions about this Privacy Policy, You can
                contact us:
              </p>
              <p>
                By email:{" "}
                <a href="mailto:support@gritlife.app">support@gritlife.app</a>
              </p>
              <p>
                By visiting this page on our website:{" "}
                <a href="https://gritlife.app">www.gritlife.app</a>
              </p>
            </Col>
          </Row>

          <Row>
            <Col>
              <h4 className="text-uppercase">Last updated: January 31, 2022</h4>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export default PrivacyPage
